@import "src/style.scss";

.Projects {
  @include themeColors($alpha: 0.8);
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 2vw;
  row-gap: 3vh;
  padding: 2%;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media only screen and (min-width: 600px){
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1000px){
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
