@import "src/style.scss";

body {
  margin: 0;
  font-family: $montserrat;
  background-color: black;

  &:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    z-index: -10;
    background: url(resources/moonrise-mobile.jpg) no-repeat center center;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    @media only screen and (min-width: 600px) {
      background: url(resources/moonrise.jpg) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
}

#root {
  min-height: 100%;
}
