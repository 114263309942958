@import "src/style.scss";

.Text {
  a:link {
    @include neuShadow(2px);
    border-radius: 5px;
    padding: 2px;
    text-decoration: none;
  }
  a:visited{
    @include neuShadow(2px);
  }
  a:active{
    @include neuShadow(2px, $down: true);
  }

  font-family: $montserrat;
  color: $text;
  border-radius: 12px;
  margin: 5px 0;
  padding: 0 10px;
}

.theme {
  @include themeColors();
}

.transparent-theme {
  @include themeColors($alpha: 0.8);
}

.neumorphic {
  @extend .theme;
  padding: 0 20px 0 20px;
  border-radius: 12px;
}

.body {
  @extend .Text;
  font-size: calc(1rem + 0.3vw);
  text-indent: 0;
  @media only screen and (min-width: 600px){
    text-indent: 2em;
  }
  line-height: 150%;
}

.small {
  @extend .Text;
  font-size: 18px;
}

.heading {
  @extend .Text;
  font-family: $raleway;
  text-indent: unset;
  font-size: 30px;
  font-weight: 1000;
  text-align: center;
  line-height: .5;
}