@import "src/style.scss";

$borderRadius: 12px;

.ImageCard {
  @include neuShadow(1px, $down: true);
  width: fit-content;
  height: fit-content;
  padding: 12px;
  border-radius: $borderRadius;
  transition: box-shadow 0.5s, transform 0.5s ease-in-out;

  #wrapper {
    position: relative;
    width: 100%;
    height: fit-content;
    img {
    border-radius: calc(#{$borderRadius}/2);
      display: block;
      width: 100%;
      object-fit: contain;
    }
    > #overlay {
      position: absolute;
      top: 0;
      bottom: 0;

      height: 100%;
      width: 100%;
      overflow: hidden;
      min-width: 0;
      border-radius: calc(#{$borderRadius}/2);

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s, visibility 0.2s;
      background: adjust-color($color: $accent-light, $alpha: -0.8);

      display: flex;
      flex-direction: column;
      // justify-content: end;
      // align-items: flex-end;
      > * {
        padding: 10px;
        align-self: flex-end;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        overflow: hidden;
        color: $text-alt;
        font-size: 20px;
        text-align: right;
      }
    }
  }
  &.Clickable {
    @extend .ImageCard;
    @include neuShadow(1px);
    &:hover {
      @extend .Clickable;
      @include neuShadow(2px);
      cursor: pointer;
    }
    &:active {
      @extend .Clickable;
      @include neuShadow(2px, $down: true);
      cursor: pointer;
    }
  }
  &:hover {
    @extend .ImageCard;
    #wrapper > #overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  &.alt-text-color {
    @extend .ImageCard;
    #wrapper #overlay * {
      color: $text;
    }
  }
}