@import "src/style.scss";

.Layout {
  min-width: 350px;
  scroll-behavior: smooth;
  height: 100%;
  .header_container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;
  }
  .Body{
    height: calc(100% - #{$header-height});
  }
}
