@import "src/style.scss";

.MainPage {
  display: grid;
  grid-template-rows: 150vmin 1fr;
  gap: 20vmax;
  align-items: center;
  justify-items: center;
  @media only screen and (min-width: 600px) {
    justify-items: right;
    grid-template-rows: 100vh 1fr;
  }
  #about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    #contact {
      padding: calc(5vw - 9px);
      display: grid;
      grid-template-rows: repeat(3, auto);
      gap: 8px;
      min-width: 340px;
      width: 35vw;
      justify-items: center;
      .ImageCard {
        width: 50%;
      }
    }
  }
  > #projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    > .heading {
      width: fit-content;
      height: fit-content;
    }
  }
}

