@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
body{margin:0;font-family:"Montserrat",sans-serif;background-color:#000}body:before{content:"";display:block;position:fixed;left:0;top:0;width:100%;min-height:100%;z-index:-10;background:url(https://nathanaelmathieu.dev/static/media/moonrise-mobile.5f7fedc8.jpg) no-repeat center center;background-size:100%}@media only screen and (min-width: 600px){body:before{background:url(https://nathanaelmathieu.dev/static/media/moonrise.c24e2680.jpg) no-repeat center center;background-size:cover}}#root{min-height:100%}
.Basinski{margin:2%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}.Basinski>*{margin-bottom:1%;min-width:300px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}@media only screen and (min-width: 450px){.Basinski .image_text :nth-child(odd){flex-basis:calc(20vw + 360px)}}@media only screen and (min-width: 1000px){.Basinski .image_text :nth-child(odd){flex-basis:40vw}.Basinski .image_text :nth-child(even){flex-basis:50vw}.Basinski .image_text:nth-of-type(2n) :nth-child(2n-1){order:2}}
.Header{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;padding:10px}.Header>a:link{background-color:#202c2e;color:#c9c9c9;box-shadow:2px 2px calc(2px * 2) #000,calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #97b2b6;padding:10px;border-radius:5px;text-decoration:none}.Header>a:visited{background-color:#202c2e;color:#c9c9c9;box-shadow:2px 2px calc(2px * 2) #000,calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #97b2b6}.Header>a:active{background-color:#c9c9c9;color:#202c2e;box-shadow:inset 2px 2px calc(2px * 2) #4a4a4a,inset calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #fff}
.ImageCard,.ImageCard.alt-text-color,.ImageCard:hover,.ImageCard.Clickable,.ImageCard.Clickable:active{background-color:#c9c9c9;color:#202c2e;box-shadow:inset 1px 1px calc(1px * 2) #4a4a4a,inset calc(0px - 1px) calc(0px - 1px) calc(1px * 2) #fff;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding:12px;border-radius:12px;transition:box-shadow .5s,-webkit-transform .5s ease-in-out;transition:box-shadow .5s,transform .5s ease-in-out;transition:box-shadow .5s,transform .5s ease-in-out,-webkit-transform .5s ease-in-out}.ImageCard #wrapper{position:relative;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.ImageCard #wrapper img{border-radius:calc(12px/2);display:block;width:100%;object-fit:contain}.ImageCard #wrapper>#overlay{position:absolute;top:0;bottom:0;height:100%;width:100%;overflow:hidden;min-width:0;border-radius:calc(12px/2);opacity:0;visibility:hidden;transition:opacity .5s,visibility .2s;background:rgba(255,255,255,.2);display:flex;flex-direction:column}.ImageCard #wrapper>#overlay>*{padding:10px;align-self:flex-end;height:calc(100% - 20px);width:calc(100% - 20px);overflow:hidden;color:#202c2e;font-size:20px;text-align:right}.ImageCard.Clickable,.ImageCard.Clickable:active{background-color:#202c2e;color:#c9c9c9;box-shadow:1px 1px calc(1px * 2) #000,calc(0px - 1px) calc(0px - 1px) calc(1px * 2) #97b2b6}.ImageCard.Clickable:hover{background-color:#202c2e;color:#c9c9c9;box-shadow:2px 2px calc(2px * 2) #000,calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #97b2b6;cursor:pointer}.ImageCard.Clickable:active{background-color:#c9c9c9;color:#202c2e;box-shadow:inset 2px 2px calc(2px * 2) #4a4a4a,inset calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #fff;cursor:pointer}.ImageCard:hover #wrapper>#overlay{visibility:visible;opacity:1}.ImageCard.alt-text-color #wrapper #overlay *{color:#c9c9c9}
.Text,.heading,.small,.body{font-family:"Montserrat",sans-serif;color:#c9c9c9;border-radius:12px;margin:5px 0;padding:0 10px}.Text a:link,.heading a:link,.small a:link,.body a:link{background-color:#202c2e;color:#c9c9c9;box-shadow:2px 2px calc(2px * 2) #000,calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #97b2b6;border-radius:5px;padding:2px;text-decoration:none}.Text a:visited,.heading a:visited,.small a:visited,.body a:visited{background-color:#202c2e;color:#c9c9c9;box-shadow:2px 2px calc(2px * 2) #000,calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #97b2b6}.Text a:active,.heading a:active,.small a:active,.body a:active{background-color:#c9c9c9;color:#202c2e;box-shadow:inset 2px 2px calc(2px * 2) #4a4a4a,inset calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #fff}.theme,.neumorphic{background-color:#202c2e;color:#c9c9c9}.transparent-theme{background-color:rgba(32,44,46,.8);color:#c9c9c9}.neumorphic{padding:0 20px 0 20px;border-radius:12px}.body{font-size:calc(1rem + 0.3vw);text-indent:0;line-height:150%}@media only screen and (min-width: 600px){.body{text-indent:2em}}.small{font-size:18px}.heading{font-family:"Raleway",sans-serif;text-indent:unset;font-size:30px;font-weight:1000;text-align:center;line-height:.5}
.IconButtons{background-color:#202c2e;color:#c9c9c9;padding:1vw;border-radius:12px;display:flex;width:300px;justify-content:space-between;align-items:center}.IconButtons a{align-self:center;border-radius:10px;padding:6px 6px 6px 7px;background-color:#202c2e;color:#c9c9c9;box-shadow:2px 2px calc(2px * 2) #000,calc(0px - 2px) calc(0px - 2px) calc(2px * 2) #97b2b6}.IconButtons a div svg{color:inherit}.IconButtons a:hover{background-color:#202c2e;color:#c9c9c9;box-shadow:3px 3px calc(3px * 2) #000,calc(0px - 3px) calc(0px - 3px) calc(3px * 2) #97b2b6;cursor:pointer}.IconButtons a:active{background-color:#c9c9c9;color:#202c2e;box-shadow:inset 4px 4px calc(4px * 2) #4a4a4a,inset calc(0px - 4px) calc(0px - 4px) calc(4px * 2) #fff}.IconButtons a:active div svg{color:inherit}
.Layout{min-width:350px;scroll-behavior:smooth;height:100%}.Layout .header_container{position:-webkit-sticky;position:sticky;top:0;width:100%;z-index:99}.Layout .Body{height:calc(100% - 50px)}
.Cube{margin:2%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}.Cube>*{margin-bottom:1%;min-width:300px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}@media only screen and (min-width: 450px){.Cube .image_text :nth-child(odd){flex-basis:calc(20vw + 360px)}}@media only screen and (min-width: 1000px){.Cube .image_text :nth-child(odd){flex-basis:40vw}.Cube .image_text :nth-child(even){flex-basis:50vw}.Cube .image_text:nth-of-type(2n) :nth-child(2n-1){order:2}}
.Pacman{margin:2%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around;font-size:large;text-indent:2em}.Pacman>*{margin:1%;min-width:330px;flex-basis:100vw;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}@media only screen and (min-width: 800px){.Pacman .image_text :nth-child(odd){flex-basis:30vw}.Pacman .image_text :nth-child(even){flex-basis:50vw}.Pacman .image_text:nth-of-type(2n) :nth-child(2n-1){order:2}}@media only screen and (min-width: 1250px){.Pacman{font-size:x-large}}
.Template{margin:2%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}.Template>*{margin-bottom:1%;min-width:300px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}@media only screen and (min-width: 450px){.Template .image_text :nth-child(odd){flex-basis:calc(20vw + 360px)}}@media only screen and (min-width: 1000px){.Template .image_text :nth-child(odd){flex-basis:40vw}.Template .image_text :nth-child(even){flex-basis:50vw}.Template .image_text:nth-of-type(2n) :nth-child(2n-1){order:2}}
.Tempist{margin:2%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}.Tempist>*{margin-bottom:1%;min-width:300px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}@media only screen and (min-width: 450px){.Tempist .image_text :nth-child(odd){flex-basis:calc(20vw + 360px)}}@media only screen and (min-width: 1000px){.Tempist .image_text :nth-child(odd){flex-basis:40vw}.Tempist .image_text :nth-child(even){flex-basis:50vw}.Tempist .image_text:nth-of-type(2n) :nth-child(2n-1){order:2}}
.Projects{background-color:rgba(32,44,46,.8);color:#c9c9c9;display:grid;grid-template-columns:minmax(0, 1fr);grid-column-gap:2vw;-webkit-column-gap:2vw;column-gap:2vw;grid-row-gap:3vh;row-gap:3vh;padding:2%;justify-content:center;align-items:center;height:100%}@media only screen and (min-width: 600px){.Projects{grid-template-columns:repeat(2, minmax(0, 1fr))}}@media only screen and (min-width: 1000px){.Projects{grid-template-columns:repeat(3, minmax(0, 1fr))}}
.Layout body{display:flex;flex-direction:column;align-items:center}.Layout body h1{font-family:"Raleway",sans-serif}.Layout body p{font-family:"Montserrat",sans-serif}
.MainPage{display:grid;grid-template-rows:150vmin 1fr;grid-gap:20vmax;gap:20vmax;align-items:center;justify-items:center}@media only screen and (min-width: 600px){.MainPage{justify-items:right;grid-template-rows:100vh 1fr}}.MainPage #about{display:flex;flex-direction:row;align-items:center;justify-content:flex-end}.MainPage #about #contact{padding:calc(5vw - 9px);display:grid;grid-template-rows:repeat(3, auto);grid-gap:8px;gap:8px;min-width:340px;width:35vw;justify-items:center}.MainPage #about #contact .ImageCard{width:50%}.MainPage>#projects{display:flex;flex-direction:column;align-items:center;width:100%;height:100%}.MainPage>#projects>.heading{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}
