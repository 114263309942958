@import "src/style.scss";

.IconButtons {
  @include themeColors();
  padding: 1vw;
  border-radius: 12px;
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
  a {
    align-self: center;
    div svg {
      color: inherit;
    }
    border-radius: 10px;
    padding: 6px 6px 6px 7px;
    @include neuShadow(2px);
  }
  a:hover {
    @include neuShadow(3px);
    cursor: pointer;
  }
  a:active{
    @include neuShadow(4px, $down: true);
    div svg {
      color: inherit;
    }
  }
}
