@import url("https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap");

//Font Variables
$raleway: "Raleway", sans-serif;
$montserrat: "Montserrat", sans-serif;


//color database
$wesleyan-red: #d72121;
$wesleyan-accent1: #ac1a1a;
$wesleyan-accent2: #ff2828;
$wesleyan-black: #101820;
$blue-grey: #2e8888;
$blue-grey-light: #3fbdbd;
$blue-grey-dark: #005555;
$blue-pale-transparent: #1d6a9a4d;

//Theme Colors
$main: #202c2e;
$main-dark: darken($main, 50);
$main-light: lighten($main, 50);

$accent: #c9c9c9;
$accent-dark: darken($accent, 50);
$accent-light: lighten($accent, 50);

$text: $accent;
$text-alt: $main;

//Other Variables
$header-height: 50px;

//Mixins
@mixin alternatingPictureLayout($columnBreakpoint) {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  > * {
    margin-bottom: 1%;
    min-width: 300px;
    
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
  }
  @media only screen and (min-width: 450px) {
    .image_text {
      :nth-child(odd) {
        flex-basis: calc(20vw + 360px);
      }
    }
  }
  @media only screen and (min-width: #{$columnBreakpoint}) {
    .image_text {
      :nth-child(odd) {
        flex-basis: 40vw;
      }
      :nth-child(even) {
        flex-basis: 50vw;
      }
    }
    .image_text:nth-of-type(2n) {
      :nth-child(2n-1){
        order: 2;
      }
    }
  }
}

@mixin themeColors($invert: false, $alpha: 1) {
  @if $invert == false {
    background-color: rgba($main, $alpha);
    color: $text;
  } @else {
    background-color: rgba($accent, $alpha);
    color: $text-alt;
  }
}

@mixin neuShadow($size, $down: false) { //top-left light source
  @if $down == false {
    @include themeColors();
    box-shadow: $size $size calc(#{$size} * 2) $main-dark,
                calc(0px - #{$size}) calc(0px - #{$size}) calc(#{$size} * 2) $main-light;
  } @else {
    @include themeColors($invert: true);
    box-shadow: inset $size $size calc(#{$size} * 2) $accent-dark,
                inset calc(0px - #{$size}) calc(0px - #{$size}) calc(#{$size} * 2) $accent-light;
  }
}